<template>
  <div id="home">
    <Slideshow />
  </div>
</template>

<script>
import Slideshow from '../Utilities/Slideshow';
export default {
  components: {
    Slideshow,
  },
};
</script>

<style></style>
